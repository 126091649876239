import React from "react"
import Header from "../components/header"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

const BloodDrivePage = props => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="blood-drive" language={language}>
        <SEO
          title="2021 Blood Drive at Amarillo Oral & Maxillofacial Surgery & Dental Implants"
          description="Learn about the 2021 Blood Drive program.'"
        />

        <div className="basic-page blood-drive-page joshua-tree-content">
          <div className="program-sub-container">
            {/* <h1 className="">2019 Blood Drive</h1> */}
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/v1594831877/Programs/aoms-blood-drive-blood-drop-background.png"
              alt="2019 Blood Drive"
              width="100%"
            />
            <h1>
              CINCO DE MAYO
              <br />
              BLOOD DRIVE
            </h1>
            <h2>Be a Lifesaver</h2>
          </div>
          <div className="back-button"></div>

          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <div className="blood-grid">
                <div>
                  <h2>Thank You for Giving Blood!</h2>
                  <p>
                    From surgery to fighting cancer and other illnesses, donated
                    blood is used every 2 seconds in the United States.
                  </p>

                  <p>
                    The team at Amarillo Oral & Maxillofacial Surgery & Dental
                    Implants partnered with Coffee Memorial Blood Center for our
                    first blood drive. Thanks to all our donors, the blood
                    provided will help save many lives!
                  </p>

                  <p>
                    We thank you for your contribution and look forward to
                    hosting more life-changing events in the future.
                  </p>
                  <p>
                    <i>
                      Learn more about blood donation and schedule your
                      appointment to be a lifesaver{" "}
                      <a
                        href="https://www.thegiftoflife.org/web/index.php"
                        target="_blank"
                        title="Learn mroe about blood donation">
                        here
                      </a>
                      .
                    </i>
                  </p>
                </div>

                {/* SIDE BOX */}
                {/* <div className="blood-box">
                  <h5>Event Information:</h5>
                  <div className="blood-address">
                    <p>Wednesday, May 5, 2021</p>
                    <p>11:00 AM–3:00 PM</p>
                  </div>
                  <br />
                  <div className="blood-address">
                    <p>Amarillo Oral & Maxillofacial Surgery & Dental Implants</p>
                    <p>5051 S Soncy Rd</p>
                    <p>Amarillo, TX 79119</p>
                  </div>

                  <h5 style={{ marginTop: "32px" }}>Appointments:</h5>
                  <p>
                    Schedule your donation appointment today. We thank you for
                    your commitment to saving lives.
                  </p>
                  <a
                    style={{ marginTop: "50px", display: "block" }}
                    className="standard-button standalone"
                    href="https://www.yourbloodinstitute.org/donor/schedules/drive_schedule/360252"
                    target="_blank"
                  >
                    Make Appointment
                  </a>
                </div> */}
                {/* SIDE BOX */}
              </div>
            </div>
            <div className="column is-3"></div>
          </div>

          {/* <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h3>Important Information for Donors:</h3>
              <ul className="color-bullet">
                <li>
                  You must be at least 16 years of age (if you are under the age
                  of 18, you must bring a signed parental consent form).
                </li>
                <li>You should be in good health.</li>
                <li>
                  Eat a healthy, well-balanced meal 2 hours ahead of your
                  donation.
                </li>
                <li>
                  Stay well-hydrated the day before and the day of donation
                  (water is best).
                </li>
                <li>A government-issued ID will be required.</li>
                <li>
                  Prior to arrival, donors should complete the{" "}
                  <a
                    target="_blank"
                    href="https://www.thegiftoflife.org/web/index.php?page=donor-express"
                  >
                    Donor EXPRESS Questionnaire
                  </a>{" "}
                  online.
                </li>
              </ul>

              <a
                    className="center-button"
                    href="https://www.bloodhero.com/index.cfm?group=op&step=2&opid=939946&opidh=3A35CA6B5D068B0BD492CA9F3E38BFE6&idt=44020.3817014"
                    target="_blank"
                  >
                    <button className="center-button"> Make Appointment</button>
                  </a>
            </div>
            <div className="column is-3"></div>
          </div> */}
        </div>

        <div className="columns">
          <div className="column is-3"></div>
          <div className="column safe-donate">
            <div className="border-wrapper">
              <div className="blood-flex">
                <img
                  className="blood-drive-heart"
                  src="https://res.cloudinary.com/nuvolum/image/upload/v1594761580/Programs/blood-drive-heart.png"
                />

                <div>
                  <h3>Is it safe to donate?</h3>
                  <p>
                    Yes! Blood donation is a part of essential medical services.
                    Coffee Memorial Blood Center staff members wear face masks
                    at all times and provide adequate spacing of chairs in the
                    waiting, donation, and refreshment areas. Donors are welcome
                    to wait in their cars and receive a call or text when it’s
                    time to donate. All staff members follow rigorous
                    disinfection, health screening, and distancing protocols to
                    lower the risk of transmission at donation sites. We also
                    ask that donors wear a personal mask throughout their
                    donation.
                  </p>
                </div>
              </div>

              <a
                style={{ margin: "auto", marginTop: "50px", display: "block" }}
                className="standard-button standalone"
                href="https://www.yourbloodinstitute.org/donor/schedules/drive_schedule/360252"
                target="_blank">
                Make Appointment
              </a>
            </div>
          </div>
          <div className="column is-3"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default BloodDrivePage
